import { Breadcrumb, Menu, Layout, Button, Space } from 'antd';
import { useRouter } from 'next/router';
import Link from 'next/link';
import React from 'react';
import Image from 'next/image';

import useUser from '../../lib/useUser';

import styles from './layout.module.scss';
import { request } from 'src/lib/request';
import withSession, { getUserServer } from '../../lib/withSession';

const { Header, Content, Footer } = Layout;

const LayoutComponent = (props) => {
  const { user, mutateUser } = useUser({
    redirectTo: '/login',
  });
  const router = useRouter();

  const logout = async () => {
    try {
      await mutateUser(request('/api/logout'));
      router.push('/login');
    } catch (error) {}
  };

  return (
    <Layout className='layout'>
      <Header>
        <div className={styles.logo}>
          <Image src='/axle.png' width='auto' height='32px' />
        </div>

        {user?.isLoggedIn && (
          <Menu theme='dark' mode='horizontal' selectedKeys={[router.pathname]}>
            <Menu.Item key='/'>
              <Link href='/'>Home</Link>
            </Menu.Item>
            <Menu.Item key='/loadboard'>
              <Link href='/loadboard'>Loadboard</Link>
            </Menu.Item>
            <Menu.Item key='/account'>
              <Link href='/account'>Account</Link>
            </Menu.Item>
          </Menu>
        )}

        <div className={styles.rightNav}>
          {!user?.isLoggedIn && (
            <Link href='/login'>
              <Button type='default'>Login</Button>
            </Link>
          )}
          {user?.isLoggedIn && (
            <Button type='default' onClick={logout}>
              Logout
            </Button>
          )}
        </div>
      </Header>
      <Content className={styles.content}>{props.children}</Content>
      <Footer className={styles.footer}>
        Axle ©2021 Created by TruckSmarter
      </Footer>
    </Layout>
  );
};

export default LayoutComponent;
